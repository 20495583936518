import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";
import { logoutUser } from "../../utils/Api";
import IsToken from "../../utils/Handeler/IsToken";
import LocationDateFilterModal from "../Modal/LocationDateFilterModal";
import CustomToast from "../CustomToast";
import { slide as Menu } from "react-burger-menu";

export default function Header({
  cartItems,
  locationAndDates,
  setLocationAndDates,
}) {
  const [showLocationDateModal, setShowLocationDateModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for controlling burger menu

  const openLocationDateModal = () => {
    setShowLocationDateModal(true); // Show the modal
  };

  const closeLocationDateModal = () => {
    setShowLocationDateModal(false); // Hide the modal
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const token = user ? user.token : null;
  const name = user ? user.name : null;
  const navigateTo = useNavigate();

  const handleLogout = async () => {
    if (IsToken()) {
      try {
        const data = await logoutUser(token);
        if (data.success) {
          localStorage.removeItem("user");
          sessionStorage.removeItem("user");
          // CustomToast("Logout successful", "success");
          navigateTo("/login");
        } else {
          CustomToast("Logout failed", "error");
        }
      } catch (error) {
        console.error("Logout error:", error);
        CustomToast("An error occurred. Please try again.", "error");
      }
    } else {
      CustomToast("No user is currently logged in.", "warning");
    }
  };

  // Clear the selected location and dates and open the modal again
  function handleClearLocationDateFilter() {
    // If there are items in the cart, prompt the user to clear the cart first
    if (cartItems.length > 0) {
      CustomToast(
        "You need to clear the cart before changing the location or date.",
        "warning"
      );
      return;
    }

    // If the cart is empty, proceed to clear the location and dates
    localStorage.removeItem("locationDateFilter");
    // CustomToast("Location and date has been cleared.", "info");
    // Reset state to remove the selected location and dates
    setLocationAndDates({
      locationId: null,
      locationName: null,
      startDate: null,
      endDate: null,
    });
    openLocationDateModal();
  }

  // Handle location and date submit from the modal
  const handleLocationDateSubmit = (formData) => {
    const { locationId, locationName, startDate, endDate, timeSlot } = formData;

    // Update localStorage
    localStorage.setItem(
      "locationDateFilter",
      JSON.stringify({ locationId, locationName, startDate, endDate, timeSlot })
    );

    // Update the state to reflect the changes immediately
    setLocationAndDates({
      locationId,
      locationName,
      startDate,
      endDate,
      timeSlot,
    });

    closeLocationDateModal(); // Close the modal after saving data
  };

  // Calculate total quantity of items in the cart
  const totalCartQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  // Toggle the burger menu
  const handleBurgerMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close burger menu when a menu item is clicked
  const closeBurgerMenu = () => {
    setIsMenuOpen(false);
  };

  // Format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  return (
    <>
      <div className="header section">
        <div className="header-bottom d-none d-lg-block">
          <div className="container position-relative">
            <div className="row align-self-center">
              <div className="col-auto align-self-center">
                <div className="header-logo">
                  <Link to="/">
                    <img src={logo} alt="Site Logo" />
                  </Link>
                </div>
              </div>
              <div className="col align-self-center">
                <div className="header-actions">
                  {IsToken() && (
                    <>
                      <button onClick={handleClearLocationDateFilter}>
                        Change Date
                      </button>

                      {/* Display selected location and dates or show 'Please Select' */}
                      {locationAndDates.locationName &&
                      locationAndDates.startDate &&
                      locationAndDates.endDate ? (
                        <p>
                          {`Location: ${locationAndDates.locationName}, `}

                          {/* Conditionally show based on more than a day */}
                          {locationAndDates.startDate ===
                          locationAndDates.endDate ? (
                            <>
                              Date: {formatDate(locationAndDates.startDate)}{" "}
                              {/* Show time slot */}
                              {locationAndDates.timeSlot === "morning"
                                ? "(Morning 10 AM - 4 PM)"
                                : locationAndDates.timeSlot === "evening"
                                ? "(Evening 4 PM - Midnight)"
                                : locationAndDates.timeSlot === "fullday"
                                ? "(Full Day)"
                                : ""}
                            </>
                          ) : (
                            <>
                              Dates:{" "}
                              {`${new Date(
                                locationAndDates.startDate
                              ).toLocaleDateString("en-GB", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })} to ${new Date(
                                locationAndDates.endDate
                              ).toLocaleDateString("en-GB", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })}`}
                            </>
                          )}
                        </p>
                      ) : (
                        <p>
                          No Location and dates selected{" "}
                          <button
                            onClick={openLocationDateModal}
                            style={{ color: "#ff7004" }}
                          >
                            Please Select
                          </button>
                        </p>
                      )}
                    </>
                  )}
                  <button
                    className="dropdown-toggle header-action-btn"
                    data-bs-toggle="dropdown"
                  >
                    {token ? (
                      `Hi, ${name}`
                    ) : (
                      <>
                        <i
                          className="icon-user"
                          style={{ marginRight: "10px" }}
                        ></i>{" "}
                        Sign in / Register
                      </>
                    )}
                  </button>
                  <ul className="dropdown-menu dropdown-menu-right">
                    {token ? (
                      <li>
                        <Link className="dropdown-item" to="/my-account">
                          My Account
                        </Link>
                        <Link className="dropdown-item" to="/order-history">
                          Order history
                        </Link>
                        <Link className="dropdown-item" onClick={handleLogout}>
                          Sign Out
                        </Link>
                      </li>
                    ) : (
                      <li>
                        <Link className="dropdown-item" to="/login">
                          Sign in
                        </Link>
                        <Link className="dropdown-item" to="/register">
                          Register
                        </Link>
                      </li>
                    )}
                  </ul>

                  {/* Cart Icon */}
                  <Link
                    to="/cart"
                    className="header-action-btn header-action-btn-cart pr-0"
                  >
                    <i className="icon-handbag"></i>
                    <span className="header-action-num">
                      {totalCartQuantity}
                    </span>
                  </Link>
                  <Link
                    id="#offcanvas-mobile-menu"
                    to="#"
                    className="header-action-btn header-action-btn-menu offcanvas-toggle d-lg-none"
                  >
                    <i className="icon-menu"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* for mobile start*/}

      {/* Mobile Top Bar */}
      <div className="mobile-top-bar d-lg-none">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              {/* Display the selected location and dates */}
              {locationAndDates.locationName &&
              locationAndDates.startDate &&
              locationAndDates.endDate ? (
                <p className="mobile-location-date">
                  {`Location: ${
                    locationAndDates.locationName
                  }, Dates: ${new Date(
                    locationAndDates.startDate
                  ).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })} to ${new Date(
                    locationAndDates.endDate
                  ).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })}`}
                </p>
              ) : (
                <p className="mobile-location-date">
                  No Location and dates selected{" "}
                  <button
                    onClick={openLocationDateModal}
                    style={{ color: "#ff7004" }}
                  >
                    Please Select
                  </button>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Existing Mobile Header Code */}
      <div className="header-bottom d-lg-none sticky-nav bg-white">
        <div className="container position-relative">
          <div className="row align-self-center">
            <div className="col-auto align-self-center">
              <div className="header-logo">
                <Link to="/">
                  <img src={logo} alt="Site Logo" />
                </Link>
              </div>
            </div>

            <div className="col align-self-center">
              <div className="header-actions">
                {/* Calendar Icon for location/date selection */}
                <div className="header_account_list">
                  <Link
                    to="#"
                    className="header-action-btn search-btn"
                    onClick={handleClearLocationDateFilter}
                  >
                    <i className="icon-calendar"></i>
                  </Link>
                </div>

                <div className="header-bottom-set dropdown">
                  <button
                    className="dropdown-toggle header-action-btn"
                    data-bs-toggle="dropdown"
                  >
                    <i className="icon-user"></i>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-right">
                    {token ? (
                      <li>
                        <Link className="dropdown-item" to="/my-account">
                          My Account
                        </Link>
                        <Link className="dropdown-item" to="/order-history">
                          Order history
                        </Link>
                        <Link className="dropdown-item" onClick={handleLogout}>
                          Sign Out
                        </Link>
                      </li>
                    ) : (
                      <li>
                        <Link className="dropdown-item" to="/login">
                          Sign in
                        </Link>
                        <Link className="dropdown-item" to="/register">
                          Register
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>

                {/* Cart Icon */}
                <Link
                  to="/cart"
                  className="header-action-btn header-action-btn-cart offcanvas-toggle pr-0"
                >
                  <i className="icon-handbag"></i>
                  <span className="header-action-num">{totalCartQuantity}</span>
                </Link>

                {/* Mobile Burger Menu Toggle */}
                <button
                  className="header-action-btn offcanvas-toggle d-lg-none"
                  onClick={handleBurgerMenuToggle} // Handle burger menu click
                >
                  <i className="icon-menu"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* for mobile end*/}
      <div
        id="offcanvas-mobile-menu"
        className="offcanvas offcanvas-mobile-menu"
      >
        <button className="offcanvas-close"></button>
        <div className="inner customScroll">
          <div className="offcanvas-menu mb-20px">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/shop">Shop</Link>
              </li>
              <li>
                <Link to="/">About Us</Link>
              </li>
              <li>
                <Link to="/">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="bg-black d-none d-lg-block sticky-nav">
        <div className="container position-relative">
          <div className="row">
            <div className="col-md-12 align-self-center">
              <div className="main-menu">
                <ul>
                  <li className="dropdown">
                    <Link to="#">Home</Link>
                  </li>
                  <li>
                    <Link to="/">About us</Link>
                  </li>
                  <li className="dropdown position-static">
                    <Link to="/shop">
                      Shop 
                    </Link>
                    {/* <ul className="mega-menu d-block">
                      <li className="d-flex">
                        <ul className="d-block">
                          <li className="title">
                            <Link to="/">Category</Link>
                          </li>
                          <li>
                            <Link to="/">Category</Link>
                          </li>
                        </ul>
                        <ul className="d-block">
                          <li className="title">
                            <Link to="/">Category</Link>
                          </li>
                          <li>
                            <Link to="/">Product Single</Link>
                          </li>
                        </ul>
                        <ul className="d-block">
                          <li className="title">
                            <Link to="/">Category</Link>
                          </li>
                          <li>
                            <Link to="/">Product Slider</Link>
                          </li>
                        </ul>
                        <ul className="d-block">
                          <li className="title">
                            <Link to="/">Category</Link>
                          </li>
                          <li>
                            <Link to="/">Cart Page</Link>
                          </li>
                        </ul>
                      </li>
                    </ul> */}
                  </li>
                  <li className="dropdown">
                    <Link to="/">
                      Policy <i className="ion-ios-arrow-down"></i>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/">Faq Page</Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link to="/">Contact us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Burger Menu for mobile Start */}
      <Menu right isOpen={isMenuOpen} onClose={closeBurgerMenu}>
        <Link to="/" onClick={closeBurgerMenu}>
          Home
        </Link>
        <Link to="/shop" onClick={closeBurgerMenu}>
          Shop
        </Link>
        <Link to="/" onClick={closeBurgerMenu}>
          About Us
        </Link>
        <Link to="/" onClick={closeBurgerMenu}>
          Contact Us
        </Link>
      </Menu>
      {/* Burger Menu for mobile End*/}

      {/* Location Date Filter Modal */}
      {showLocationDateModal && (
        <LocationDateFilterModal
          show={showLocationDateModal}
          onClose={closeLocationDateModal}
          onSubmit={handleLocationDateSubmit} // Pass this function to the modal
        />
      )}
    </>
  );
}
